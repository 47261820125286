import React, {Component} from 'react'
import MaskedInput from 'react-text-mask'
import {Redirect} from 'react-router-dom'
import ImageUploader from 'react-images-upload';

import {URL} from './../../services/api';
import {getItemLocalStorage} from './../../services/extra';
import './styles.css'

class Input extends Component {

    state = {
        valueInput: '',
        redirect: false,
        clickUso: false,
        clickContrato: false,
        isContrato: false,
        pictures: [],
        picturesIndex: [],
    }

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        if (this.props.id === 'concorda_termos') {
            this.setState({isContrato: true});
        }
        const element = document.getElementById('link-uso');
        if (element) {
            this.makeEventListenerContrato();

        }

    }

    makeEventListenerContrato() {
        const element = document.getElementById('link-uso');
        element.addEventListener("click", this.setClickUso, false);
        const elementContrato = document.getElementById('link-contrato');
        elementContrato.addEventListener("click", this.setClickContrato, false);
    }

    setClickUso = () => {
        this.setState({clickUso: true});
    }

    setClickContrato = () => {
        this.setState({clickContrato: true});
    }

    onChange = (event) => {
        this.setState({valueInput: event.target.value});
    }

    onSubmit = (e) => {
        e.preventDefault();
        return this.props.submitUser(this.state.valueInput);
    }

    async handleClick(e, value, text, redirectTo = '') {
        e.preventDefault();

        const {isContrato, clickContrato, clickUso} = this.state;

        const isValido = (!isContrato || (isContrato && clickUso && clickContrato));
        if (!isValido) {
            alert('Por gentileza, leia os Termos de Uso e o Contrato antes de prosseguir.')
        } else {
            if (redirectTo !== '') {
                this.setState({redirect: redirectTo})
            } else if (value !== '' && redirectTo === '') {
                if (this.props.id === 'documento_imagem') {
                    if (this.state.pictures && this.state.pictures.length > 0) {
                        if (this.state.pictures.length === 2) {
                            return this.props.submitUser(this.state.pictures, text);
                        } else if(this.state.pictures.length > 2) {
                            alert('Uma imagem da frente e outra do verso do seu documento, são suficientes!')
                        } else {
                            alert('Por gentileza, envie uma imagem da frente e outra do verso do seu documento!')
                        }
                    } else {
                        alert('Por gentileza, envie uma imagem da frente e outra do verso do seu documento.')
                    }
                } else {
                    return this.props.submitUser(value, text);
                }
            }
        }

    }

    onDrop = picture => {
        this.setState({pictures: [], picturesIndex: []});
        picture.map((item, index) => this.getBase64(item, index));
    }

    getBase64(file, index) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        return reader.onload = () => {
            const item = {
                b64: reader.result,
                fileName: file.name,
            };

            if (this.state.picturesIndex.indexOf(index) < 0) {
                this.setState({
                    pictures: this.state.pictures.concat(item),
                    picturesIndex: this.state.picturesIndex.concat(index),
                });
            }
        };
    }

    clearData = () => {
        localStorage.removeItem('stepAtual');
        localStorage.removeItem('dataMatricula');
        localStorage.removeItem('dataAtualizacao');
    }

    render() {

        const {redirect, isContrato, clickContrato, clickUso} = this.state;

        const isValido = (!isContrato || (isContrato && clickUso && clickContrato));

        if (redirect !== false) {
            if (redirect === '/pagamento') {
                return <Redirect to='/pagamento'/>;
            }
            if (redirect === '/nivelamento') {
                //const dataMatricula = JSON.parse(localStorage.getItem('dataMatricula'));
                const dataMatricula = getItemLocalStorage('dataMatricula');
                console.log(dataMatricula);

                let url = "https://matricula.rockfeller.com.br/nivelamento.php?email-aluno=" + dataMatricula.email_aluno + "&sala=AD0C67"
                this.clearData();

                window.location.assign(url);
            }
            if (redirect === '/definirsenha') {
                //const dataMatricula = JSON.parse(localStorage.getItem('dataMatricula'));
                const dataMatricula = getItemLocalStorage('dataMatricula');

                let url = `${URL}/enrollment/nao-fazer-nivelamento/` + dataMatricula.idEnrollment
                this.clearData();

                window.location.assign(url);
            }
            if (redirect === '/definirsenha2') {
                //const dataMatricula = JSON.parse(localStorage.getItem('dataMatricula'));

                const dataMatricula = getItemLocalStorage('dataMatricula');

                let url = `${URL}/enrollment/definir-senha/` + dataMatricula.idEnrollment
                this.clearData();
                window.location.assign(url);
            }
        }

        if (this.props.inputs !== '') {
            let mask = [];
            if (this.props.inputs.mask === 'phone') {
                mask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
            }
            if (this.props.inputs.mask === 'cpf') {
                mask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
            }
            if (this.props.inputs.mask === 'cep') {
                mask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
            }

            if (this.props.inputs.mask === 'cep') {
                mask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
            }
            if (this.props.inputs.mask === 'data') {
                mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
            }

            return (
                <div id="inputs">
                    <div className="input-container">
                        <form id="form-input" onSubmit={this.onSubmit}>
                            {this.props.inputs.mask !== '' ?
                                <MaskedInput
                                    mask={mask}
                                    type={this.props.inputs.type}
                                    defaultValue={this.state.valueInput}
                                    onChange={this.onChange}
                                    className="form-control"
                                    placeholder={this.props.inputs.placeholder}
                                    autoFocus
                                ></MaskedInput>
                                :
                                <input type={this.props.inputs.type} defaultValue={this.state.valueInput}
                                       onChange={this.onChange} className="form-control"
                                       placeholder={this.props.inputs.placeholder} required autoFocus
                                       id="input-text"></input>
                            }

                            <button type="submit" className="wrn-button">Ok</button>
                        </form>
                    </div>
                </div>
            )
        } else if (this.props.buttons !== '') {
            return (
                <div id="inputs">
                    <div className={this.props.isItems ? 'conversation-input row w-100' : 'input-container'}>
                        {Object.keys(this.props.buttons).map((value, index) => (
                            this.props.buttons[value].type === 'IMAGE' ? (
                                <ImageUploader
                                    withIcon={false}
                                    withLabel={false}
                                    buttonText={this.props.buttons[value].text}
                                    onChange={this.onDrop}
                                    imgExtension={['.jpg', '.png', '.jpeg']}
                                    maxFileSize={542880}
                                    buttonClassName="wrn-button"
                                    withPreview={true}
                                    key={value}
                                />
                            ) : (
                                this.props.isItems ? (
                                    <div key={value} className="col-4 text-center">
                                        <button
                                            type="button"
                                            defaultValue={value}
                                            key={value}
                                            onClick={(e) => this.handleClick(e, value, this.props.buttons[value].text, this.props.buttons[value].redirectTo)}
                                            className={isValido ? 'wrn-button' : 'invalid-button'}
                                            title={!isValido ? 'Clique e leia os termos de uso e o contrato' : ''}
                                        >{this.props.buttons[value].text}</button>
                                    </div>
                                ) : (
                                    <button
                                        type="button"
                                        defaultValue={value}
                                        key={value}
                                        onClick={(e) => this.handleClick(e, value, this.props.buttons[value].text, this.props.buttons[value].redirectTo)}
                                        className={isValido ? 'wrn-button' : 'invalid-button'}
                                        title={!isValido ? 'Clique e leia os termos de uso e o contrato' : ''}
                                    >{this.props.buttons[value].text}</button>
                                )
                            )
                        ))}
                    </div>
                </div>
            )
        }
    }

}

export default Input;