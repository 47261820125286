import React, {Component} from 'react'
import Typed from 'react-typed';
import api from '../../services/api';
import {addItemLocalStorage, getItemLocalStorage} from '../../services/extra';
import './styles.css'
import '../../../node_modules/font-awesome/css/font-awesome.min.css';
import queryString from 'query-string'
import loading from '../../assets/images/loading.svg'
import Input from '../../components/Input'

export default class Main extends Component {

    constructor(props) {
        super(props);
        this.submitUser = this.submitUser.bind(this);
    }

    state = {
        showInput: '',
        response: {},
        messages: [],
        data: [],
        curso: '',
        modalidade: '',
        aluno: null,
        vendedor: null,
        showLoading: true,
    }

    async componentDidMount() {
        const storage = await getItemLocalStorage('dataMatricula');
        if (storage) {
            await navigator.geolocation.getCurrentPosition(location => {
                storage['latitude'] = location.coords.latitude;
                storage['longitude'] = location.coords.longitude;
            });
            await addItemLocalStorage(storage, 'dataMatricula');
        }
        //const ultimaAtualizacao = new Date(localStorage.getItem('dataAtualizacao'));
        const ultimaAtualizacao = new Date(getItemLocalStorage('dataAtualizacao'));

        if (ultimaAtualizacao) {
            const now = new Date();

            const diffTime = Math.abs(now - ultimaAtualizacao);
            const diffDays = Math.abs(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays >= 1) {
                this.clearData();
            }
        }

        //parseInt(localStorage.getItem('stepAtual'))
        if (parseInt(getItemLocalStorage('stepAtual')) > 0) {
            //this.loadMensage(localStorage.getItem('stepAtual'));
            this.loadMensage(getItemLocalStorage('stepAtual'));
        } else {
            this.loadMensage();
        }
        this.setState(this.props.match.params);
        if (queryString.parse(this.props.location.search).aluno !== undefined) {
            const query = queryString.parse(this.props.location.search)
            this.setState({aluno: query.aluno, vendedor: query.vendedor})
            //localStorage.setItem('dataMatricula', JSON.stringify({idAluno: query.aluno, idVendedor: query.vendedor}))
            addItemLocalStorage({idAluno: query.aluno, idVendedor: query.vendedor}, 'dataMatricula');
        }
    }

    loadMensage = async (step = 1) => {

        const response = await api.get(`/matricula-chat/${step}.json`);
        const messages = response.data.nextQuestion.messages;

        this.setState({
            messages: [...this.state.messages, messages[0]],
            response: response.data.nextQuestion,
            showLoading: false
        });
        //localStorage.setItem('stepAtual', step);
        addItemLocalStorage(step, 'stepAtual');

        //localStorage.setItem('dataAtualizacao', new Date());
        addItemLocalStorage(new Date(), 'dataAtualizacao');

        if (response.data.nextQuestion.limpaDados !== undefined && response.data.nextQuestion.limpaDados === true) {
            this.clearData();
        }

    }

    clearData = () => {
        localStorage.removeItem('stepAtual');
        localStorage.removeItem('dataMatricula');
        localStorage.removeItem('dataAtualizacao');
    }

    submitUser(message, text = null) {

        if (text) {
            this.setState({messages: [...this.state.messages, {autor: 'USER', text: text}], showInput: false});
        } else {
            this.setState({messages: [...this.state.messages, {autor: 'USER', text: message}], showInput: false});
        }
        this.setState({showLoading: true})
        //Posta dados para api a resposta e os dados do localstorage
        //Recebe o retorno com dados para salvar no local storage e a próxima pergunta

        //`/matricula-chat/${localStorage.getItem('stepAtual')}.json`
        const dataLocal = getItemLocalStorage('dataMatricula');
        api.post(`/matricula-chat/${getItemLocalStorage('stepAtual')}.json`, {
            //localStorage.getItem('dataMatricula')
            data: message, dataLocalStorage: dataLocal ? JSON.stringify(getItemLocalStorage('dataMatricula')) : null
        }).then((response) => {
            //localStorage.setItem('dataMatricula', JSON.stringify(response.data.return));
            addItemLocalStorage(response.data.return, 'dataMatricula');

            const messages = response.data.nextQuestion.messages;
            this.setState({
                messages: [...this.state.messages, messages[0]],
                response: response.data.nextQuestion,
                showLoading: false
            });
            let nextStep = parseInt(response.data.return.step);
            //localStorage.setItem('stepAtual', nextStep);
            addItemLocalStorage(nextStep, 'stepAtual');

            var conversation = document.getElementById('conversation');
            conversation.scrollTop = conversation.scrollHeight - conversation.clientHeight;

            if (response.data.nextQuestion.limpaDados) {
                this.clearData();
            }

        }).catch(() => alert('Error'));
    }

    render() {
        const {showInput, response, messages, showLoading} = this.state;
        const storage = getItemLocalStorage('dataMatricula');

        return (
            <div>
                <ul className="conversation" id="conversation">
                    {messages.map((message, index) => {
                        if (message.autor === "JOY") {
                            let controlShowInput = true;
                            if (response.buttons === '' && response.inputs === '') {
                                controlShowInput = false;
                            }
                            if (message.text.indexOf('{keyAluno}') >= 0) {
                                let keyALuno = message.text.replace('{keyAluno}', storage.key);
                                message.text = keyALuno;
                            }
                            return (
                                <li className="conversa joy w-100" key={index}>
                                    {
                                        message.type === 'IFRAME' ? (
                                            <iframe src={message.text} width="100%" height="300"/>
                                        ) : (
                                            <Typed
                                                strings={[message.text]}
                                                typeSpeed={30}
                                                backSpeed={0}
                                                backDelay={0}
                                                startDelay={500}
                                                loop={false}
                                                cursorChar={''}
                                                onStart={(arrayPos, self) => {
                                                    this.setState({showInput: false})
                                                }}
                                                onComplete={(self) => {
                                                    this.setState({showInput: controlShowInput});
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        (message.items && showInput && index === (messages.length - 1)) && (
                                            <div className="row mt-2">
                                                {
                                                    message.items && message.items.map((item, index) => (
                                                        <div key={item.id} className="col-12 col-md-4 col-xl-4 p-2">
                                                            <div style={{backgroundColor: item.color}}
                                                                 className="title text-center text-white mb-2 p-1 w-100 rounded-top">
                                                                <h5 className="plan-name">{item.name.toUpperCase()}</h5>
                                                                <h5 className="plan-price">{item.price}</h5>
                                                            </div>
                                                            {
                                                                item.items && (
                                                                    <ul key={`${item.id}-${index}`}
                                                                        title={item.description}
                                                                        className="list-items items p-1 rounded-bottom">
                                                                        {
                                                                            item.items.map(subItem => (
                                                                                <li key={subItem.id}>{subItem.name}</li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                )
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                </li>
                            )
                        } else {
                            return (
                                <li className="conversa user" key={index}>{message.text}</li>
                            )

                        }
                    })}
                </ul>
                {showInput ?
                    <Input isItems={response.isItems} buttons={response.buttons} inputs={response.inputs}
                           id={response.id}
                           submitUser={this.submitUser}/>
                    : ''}
                {showLoading ?
                    <div id="loading">
                        <img src={loading} alt="loading" width={50}/>
                    </div>
                    : ''}

            </div>

        );

    }
}
