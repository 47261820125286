import React, {Component} from 'react';
import './style.css'
import Cards from 'react-credit-cards'; 
import 'react-credit-cards/es/styles-compiled.css';
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
} from '../../services/utilsPayment';
import api from '../../services/api'
import {addItemLocalStorage, getItemLocalStorage} from '../../services/extra'

class Pagamento extends Component {

    state = {
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        focused: '',
        issuer: '',
        loadingPayment: false
    }

    handleCallback = ({issuer}, isValid) => {
        if (isValid) {
            this.setState({issuer})
        }
    }

    handleInputFocus = ({target}) => {
        this.setState({
            focused: target.name,
        })
    }   

    handleInputChange = ({target}) => {
        if (target.name === 'number') {
            target.value = formatCreditCardNumber(target.value);
        } else if (target.name === 'expiry') {
            target.value = formatExpirationDate(target.value);
        } else if (target.name === 'cvc') {
            target.value = formatCVC(target.value)
        } else if (target.name === 'quantityParcel') {
            target.value = (target.value > 0) ? target.value : 1
        }

        this.setState({[target.name]: target.value})
    }

    handleSubmit = e => {
        this.setState({loadingPayment: true})
        e.preventDefault()

        //let dataMatricula = JSON.parse(localStorage.getItem('dataMatricula'));
        let dataMatricula = getItemLocalStorage('dataMatricula');
        
        api.post(`credit-card-payment/${dataMatricula.idEnrollment}`, {
            number: this.state.number,
            name: this.state.name,
            validity: this.state.expiry,
            cvc: this.state.cvc
        })
        .then(data => {
            this.setState({loadingPayment: false})
            //console.log(data.data)
            if(data.data.return.type === "APPROVED") {
                dataMatricula = {...dataMatricula, step: 19}
                //localStorage.setItem('dataMatricula', JSON.stringify(dataMatricula))
                addItemLocalStorage(dataMatricula, 'dataMatricula');

                //localStorage.setItem('stepAtual', 22);
                addItemLocalStorage(22, 'stepAtual');

                this.props.history.push('/')
            } else {
                let message = data.data.return.message
            }
            
        })
        .catch(err => {
            this.setState({loadingPayment: false})
        }) 
        
    }

    render() {

        const {name, number, expiry, cvc, focused, loadingPayment} = this.state
        
        return( 
            <div>
                <br/><br/>
                <div className="container">
                
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col">
                            <Cards
                                number={number}
                                name={name}
                                expiry={expiry}
                                cvc={cvc}
                                focused={focused}
                                callback={this.handleCallback}
                                placeholders={{name: 'Meu Nome'}}
                                acceptedCards={['visa', 'mastercard', 'Hipercard', 'Elo', 'Amex']}
                            />
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="number"
                                    placeholder="Número do cartão"  
                                    onChange={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                    required
                                />
                            </div>
                            <div className="form-group">
                            <input 
                                    type="text" 
                                    className="form-control" 
                                    name="name"
                                    placeholder="Nome impresso no cartão"  
                                    onChange={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input 
                                        type="text" 
                                        className="form-control" 
                                        name="expiry"
                                        placeholder="Validade"  
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                        required
                                    />
                            </div>
                            <div className="form-group">
                                <input 
                                        type="text" 
                                        className="form-control" 
                                        name="cvc"
                                        placeholder="cvc"  
                                        onChange={this.handleInputChange}
                                        onFocus={this.handleInputFocus}
                                        required
                                    />
                            </div>
                            <div className="row">
                                <div className="col">
                                
                                    {loadingPayment ? 
                                        <button type="submit" className="btn btn-warning btn-block" disabled>Processando...</button>
                                        :
                                        <button type="submit" className="btn btn-success btn-block" >Cadastrar cartão de crédito</button>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </form>
                </div>
            </div>
           

        )

    }
}

export default Pagamento;