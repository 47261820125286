import React, {Component} from 'react';
import './styles.css';
import '../../../node_modules/font-awesome/css/font-awesome.min.css';

class Header extends Component {

    render() {
        return (
            <header id="main-header">
                <a href="https://www.rockfeller.com.br" title="Voltar ao site"><i className="fa fa-chevron-left"></i></a> 
                <span>Minha matrícula</span>
                <span></span>
            </header>
        );
    }
}

export default Header;