import React, { Component } from 'react';
import './styles.css'

import Header from './components/Header'
import Routes from './routes';

class App extends Component {
/*
  constructor(props) {
    super(props);
    this.state = {
      dataClass: ''
    }
  }

  componentDidMount() {
    this.setState({dataClass: this.props.dataClass})
  }
*/
  render() {
    //const { dataClass } = this.state;
    return (
      <div className="App">
        <Header />
        <Routes />
      </div>
    );
  }
}

export default App;
