import CryptoJS from "crypto-js";
import b64 from 'base-64';

const passphrase = 'JOY-API-ENROLLMENT-CHAT-9er9p5sroj51chb2a3io9jdhsh';

export function encriptString(plain_text) {
    var salt = CryptoJS.lib.WordArray.random(256);
    var iv = CryptoJS.lib.WordArray.random(16);

    var key = CryptoJS.PBKDF2(passphrase, salt, {hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999});

    var encrypted = CryptoJS.AES.encrypt(plain_text, key, {iv: iv});

    var data = {
        ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
        salt: CryptoJS.enc.Hex.stringify(salt),
        iv: CryptoJS.enc.Hex.stringify(iv)
    }

    return b64.encode(JSON.stringify(data));
}

export function decryptHash(encrypted_json_string) {
    const decode = b64.decode(encrypted_json_string);

    var obj_json = JSON.parse(decode);

    var encrypted = obj_json.ciphertext;
    var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
    var iv = CryptoJS.enc.Hex.parse(obj_json.iv);

    var key = CryptoJS.PBKDF2(passphrase, salt, {hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999});


    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {iv: iv});

    const data = decrypted.toString(CryptoJS.enc.Utf8);
    return data;
}

export function addItemLocalStorage(data, key) {
    const hash = encriptString(JSON.stringify(data));
    return localStorage.setItem(key, hash)
}

export function getItemLocalStorage(key) {
    const hash = localStorage.getItem(key);
    if (hash)
        return JSON.parse(decryptHash(hash));
    return null;
}